const About = () => {
    return (
        <div className="about">
            <h2 className="header">About Me</h2>
            <p>As a passionate and result-driven Software Engineer with over 2 years of hands-on experience, I thrive in crafting 
                efficient and scalable solutions to real-world challenges. My expertise lies in front-end development, and I'm 
                well-versed in a variety of programming languages and technologies. I love turning innovative ideas into delightful 
                digital experiences, one line of code at a time.</p>
            <div className="sections">
                <div className="section-item">
                    <div className="title-text">🚀 My Journey:</div>
                    <p>Over the past two years, I have had the opportunity to work on diverse projects, honing my skills and gaining valuable 
                        insights into the ever-evolving world of frontend development. Whether it's building responsive and intuitive 
                        interfaces, optimizing website performance, or collaborating with cross-functional teams, I'm always eager to take on 
                        new challenges.</p>
                </div>

                <div className="section-item">
                    <div className="title-text">💼 Expertise:</div>
                    <p>My expertise lies in Angular, Node.js, Java, HTML, CSS and various testing frameworks. I have a keen eye for design 
                        aesthetics and enjoy creating seamless interactions through animations and transitions. Additionally, I'm familiar 
                        with version control systems like Git and tools like Jira which allows me to work efficiently with teammates.</p>
                </div>

                <div className="section-item">
                    <div className="title-text">🔍 The User Comes First:</div>
                    <p>For me, frontend development is not just about writing code; it's about understanding the end-users and tailoring 
                    experiences that make their lives better. I firmly believe that a well-designed interface can enhance user engagement 
                    and drive business success. I'm always keen to leverage user feedback and data to iterate and improve upon my work 
                    continually.</p>
                </div>

                <div className="section-item">
                    <div className="title-text">🌱 Lifelong Learner:</div>
                    <p>The tech industry moves at a rapid pace, and I understand the importance of staying up-to-date with the latest 
                    trends and best practices. I'm an avid learner and enjoy exploring new tools, libraries, and methodologies that can 
                    push the boundaries of what's possible on the web.</p>
                </div>

                <div className="section-item">
                    <div className="title-text">🌐 Open to Opportunities:</div>
                    <p>Currently, I'm open to exploring new opportunities where I can contribute my skills to exciting projects and work 
                    alongside talented individuals. Whether it's a fast-paced startup or an established tech firm, I'm eager to be a part 
                    of a team that fosters creativity and values innovation.</p>
                </div>

                <div className="section-item">
                    <div className="title-text">🤝 Let's Connect:</div>
                    <p>If you're looking for a frontend developer who is passionate about creating intuitive and responsive web experiences,
                        let's connect! I'm always open to networking, sharing ideas, and discussing potential collaborations.</p>
                </div>
            </div>
            <p>Thank you for visiting my portfolio, and I look forward to connecting with you! Let's build something amazing together. 😊</p>
        </div>
    )
}

export default About;