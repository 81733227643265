const Home = () => {
    return (
        <div className="home">
            <span className="title">Frontend Developer</span>
            <h1>Hello, I’m <span>Anakha</span></h1>
            <p>
                I’m a frontend developer with 2+ years of experience in
                turning designs into dynamic web experiences.
            </p>
        </div>
    )
}

export default Home;